/* Desktop Version */

.IntroPgCont{
    
   
    
}
.IntroSlide{ 
    margin-top: -32px;
    background-color: rgb(0, 0, 0);
    height: 100vh;
    /* width: 100%; */
    /* margin: 1rem; */
    /* display: flex; */
}

.LeftSideDiv{
    width: 40%;
    margin-left:12%;


}

.IntroSlide1{ 
    margin-top: -32px;
    background-color: rgb(0, 0, 0);
    height: 100vh;
    /* width: 100%; */
    /* margin: 1rem; */
    display: flex;
}

.IntroSlide1Head{
    font-size: 1.6rem;
    width: 60%;
    /* text-transform :uppercase; */
    font-weight: 500;
    /* letter-spacing: .2rem; */
    color: white;
    /* background-color: black; */
    padding: .4rem 1rem;
    /* margin-left: 10rem; */
    padding-top:15vh;
    
}

.IntroSlide1Head:hover{
    color: rgb(186, 186, 186);
}


.IntroSlide1Caption{
    /* margin-top: 4rem; */
    margin-top: 40%;
    text-align: center;
    width: 100%;
    /* margin-top:-1rem; */
    font-size: 1.2rem;
    /* text-transform :uppercase; */
    font-weight: 500;
    /* letter-spacing: .1rem; */
    /* color: rgb(199, 199, 199); */
    color: rgb(65, 65, 65);
    /* background-color: black; */

    /* padding: 0rem 1rem; */
    /* margin-left: 10rem; */
    cursor: pointer;

    
}




.LogoHuCont{
    top: 0;
    position: fixed;
    cursor: pointer;


}


.LogoHu{

    width: 10rem;
    /* position: absolute; */
    
    /* position: -webkit-sticky;  */
    /* Safari */
    /* position: sticky; */
    
    /* right: 0; */

    /* left: 0; */
    


}

.LogoCaption{
    text-align: center;
    text-transform: uppercase;
    font-size: .6rem;
    padding: .2rem;
    letter-spacing: .3rem;
    color: rgb(172, 171, 171);

}



.IntroMainSecSlidersCont{

    position: fixed;
    top:14rem;
    left:2rem;
    /* right: 0; */
    /* height: 100vh; */

}

.MainSecSliderArrowBtTop{
    
    /* position: fixed; */
    /* top: 0; */
    /* right: 0; */
    width: 5rem;
    height: 5rem;
    background-color: rgb(46, 45, 45);
    border: 1px solid rgb(72, 72, 72);
   
    color: white;
    font-size: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainSecSliderArrowBtTop:hover{
    background-color: black;
    color: red;
    
}

.MainSecSliderArrowBtBottom{
    
    /* position: fixed; */
    /* bottom: 0; */
    /* right: 0; */
    width: 5rem;
    height: 5rem;
    background-color: rgb(46, 45, 45);
    border: 1px solid rgb(72, 72, 72);
    color: white;
   
    font-size: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MainSecSliderArrowBtBottom:hover{
    background-color: black;
    color: red;
}




.SingProjSlidersBtCont{

    position: absolute;
    top:1rem;
    /* left:2rem; */
    right: 1rem;
    /* margin-top: -5.6rem; */

    /* height: 100vh; */
    /* margin-right: 2rem; */

}

.SingProjSliderArrowBtTop{
    
    /* position: fixed; */
    /* top: 0; */
    /* right: 0; */
    width: 4rem;
    height: 4rem;
    background-color: rgb(105, 104, 104);
    border-top: 1px solid rgb(72, 72, 72);
    border-left: 1px solid rgb(72, 72, 72);
    border-right: 1px solid rgb(72, 72, 72);
    border-bottom: 1px solid transparent;

   
    color: white;
    font-size: 1.6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .8rem .8rem 0 0;
}

.SingProjSliderArrowBtTop:hover{
    background-color: black;
    color: blue;
    
}

.SingProjSliderArrowBtBottom{
    
    /* position: fixed; */
    /* bottom: 0; */
    /* right: 0; */
    width: 4rem;
    height: 4rem;
    background-color: rgb(105, 104, 104);
    border: 1px solid rgb(72, 72, 72);
    color: white;
   
    font-size: 1.6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:0 0 .8rem .8rem ;
}

.SingProjSliderArrowBtBottom:hover{
    background-color: black;
    color: blue;
}






.SlideInCont{
    
    z-index: 99;
    /* background-color: rgb(0, 0, 0); */
    background-color: white;
    width: 82%;
    height: 100%;
    position: fixed;
    right:0;
    top:0;
    border-left: .25px solid rgb(54, 54, 54);
    overflow-y:scroll;
    
    

}
.SlideInNav{

    display: flex;
    align-items: center;
    
    position: fixed;
    background-color: white;
    height: 5rem;
    z-index: 1;
    width: 100%;

}
.SlideInContent{
    height: 100rem;
    overflow:visible;
    /* margin-left: 1rem; */

}

.SlideInBtMid{

    /* border: 1px solid rgb(202, 202, 202); */
   

    
    margin-left: 3rem;
    height: 42px;
    padding:  0 1rem;
    /* border: 1px solid rgb(202, 202, 202); */

    border-radius: .6rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.SlideInTopBtCont{
    
    display: flex;
    /* margin-left: 3rem; */
    height: 42px;
    /* padding:  0 1rem; */
    /* border: 1px solid rgb(202, 202, 202); */

    /* border-radius: .6rem; */
    display: flex;
    align-items: center;
    cursor: pointer;

    
    color: grey;
    font-size: .8rem;
    /* position: fixed; */
    
    /* width: 20rem; */


}

.BuildBt{
    position: fixed;
    right: 2rem;
    top: 1rem;

    border-radius: .6rem;
    /* border: 1px solid grey; */
    border: 1px solid rgb(235, 232, 232);
    color: rgb(180, 178, 178);
    cursor: pointer;
    width: 6rem;
    text-align: center;

}
.BuildBt:hover{
    background-color: black;
    color: white;
}


.SlideInBtMidLeft{
    border-radius: .6rem 0 0 .6rem ;
    border-left: 1px solid rgb(184, 183, 183);
    border-top: 1px solid rgb(184, 183, 183);
    border-bottom: 1px solid rgb(184, 183, 183);
    border-right: 1px solid transparent;

   
}

.SlideInBtMidRight{
    border-radius: 0 .6rem .6rem 0 ;
    /* border-left: 1px solid grey; */
    border: 1px solid rgb(184, 183, 183);
}


.SlideInBtMidCent{

    /* border-left: 1px solid grey; */
    /* border-right: 1px solid grey; */
    border: 1px solid grey;
}



.SlideInTopBtCont:hover{
    
    /* background-color: grey; */
    border: 1px solid transparent;
    background-color: black;
    color: white;

}

.SlideInHomeBt{
    font-size: .8rem;
    font-weight: 500;
    padding: 0 .4rem;
}

.SlideInTopBt{
    
    font-size: .8rem;
    /* color: white; */
    /* color:; */
    /* padding: .4rem 1rem; */
    padding: 0 1rem;
    /* cursor: pointer; */
    /* border-right: 1px solid grey; */
    /* border: 1px solid transparent; */

}
.SlideInTopBt:active{
    color: blue;

}


.ArrowLeft{

    color: rgb(188, 188, 188);
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 1rem;
    margin-left: 2rem;
    cursor: pointer;
    /* position:fixed; */
    border: 1px solid rgb(235, 232, 232);;
    border-radius: .6rem;
    display: flex;
    align-items: center;
    width: 5rem;
    height: 42px;
    
}
.ArrowLeft:hover{
    color: white;
    /* border: 1px solid rgb(145, 145, 145); */
    border: 1px solid transparent;
    /* background-color: rgb(145, 145, 145); */
    background-color: black;
    font-weight: 500;
}

.ArrowLeft:hover .SlideInHomeBt{
    color: white;
    font-weight: 500;
}


.SlideInHead{
    /* color: white; */
    color: black;
    padding: 2rem;
    padding-left: 6%;
    padding-top: 4rem;
    font-size: 1.4rem;
    width: 40%;

}


.BoardGameImgCont{
    /* width: 20rem; */
    display: flex;
    justify-content: center;
    width: 100%;
    /* margin-left: 12%; */
    padding-top: 2rem;

}

.BoardGameImg{
    width: 25rem;

}

.RightSideDivCont{
    display: flex;
    align-items: center;

}



.RightSideDiv{
    cursor: pointer;
    position: absolute;
    right: 0px;
    /* margin-top: 6rem; */
    /* top: 0; */
    background-color:black;
    border: 1px solid rgb(57, 57, 57);
    border-right: none;
    border-radius: 1rem 0 0 1rem;
    width: 40vw;
    height: 60vh;
    /* overflow:scroll; */
    padding: 1rem 2rem;
    

}

.RightSideDiv:hover{

    border: 1px solid rgb(57, 57, 57);
    /* background-color:rgb(26, 26, 26); */
    background-color: white;
    color: white;


}


.RightSideDivHead{

    color: rgb(97, 97, 97);
    font-size: .8rem;
    font-weight: 600;
    display: flex;
    align-items: center;

    position:absolute;
    /* right: 0; */
    margin: 0 auto;
    margin-left: auto;
    margin-right: auto;

}


.RightSideDiv:hover .RightSideDivHead{

    color: white;
    color: rgb(0, 0, 0);
    /* font-size: 1.2rem; */

}

.RightSideHuBranding{

    width: 1.5rem;
    margin: .2rem;
    margin: 0.2rem 


}

.RightSideHuCapability{

    height:1.5rem;
    width: 1.5rem;
    background-color: rgb(61, 60, 60);
    color: grey;
    display: flex;
    align-items:center;
    margin: 0.2rem;
    display: flex;
    justify-content: center;

}




.StickR{

    /* position: absolute; */
    display: flex;
    /* background-color: rgb(71, 70, 70); */
    border-radius: .1rem;

    
    /* right: 20px; */
    margin: 0 .6rem;
}


.RightSideDiv:hover .StickR{
    /* background-color: white; */
}


.RightSideDiv:hover .RightSideHuCapability{
    
    color: white;

}

.RightSideDiv:hover  .IntroSlide1Caption{
    
    color: #0eef0e ;
    
}


::-webkit-scrollbar {
    width: 0px;
  }

.PastClientLogosCont::-webkit-scrollbar {
    width: 4px;
  }

.TopRightSide{

    height: 100vh;
    display: flex;
    align-items: center;


}
.PastClientLogosCont{
    width: 40%;
    height: 80vh;
    display: flex;
    align-items: center;
    position: absolute;
    top: 10%;
    right: 20px;
    /* border: 1px solid rgb(195, 195, 195); */
    border-radius: 1rem;
    overflow: scroll;
    overflow-x: hidden;
    /* opacity: 0.5; */
    /* scrollbar-width: 2px; */
    -webkit-filter: grayscale(100%); 
    filter: grayscale(100%);
    /* background-color: rgb(99, 99, 99); */
    background-color: black;
    
}

.PastClientLogosCont ::-webkit-scrollbar{

    /* scrollbar-width: 2px; */
    scrollbar-color: rgb(48, 48, 48);
    
} 



.PastClientLogosCont:hover{
    background-color: white;
    filter: grayscale(0%);
    /* opacity: 1; */

}

.PastClientLogosDiv{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 80%;
    width: 100%;
    
    

}
.PastClientLogos1{
    width: 7rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 1.5rem;

}
.PastClientLogos2{
    width: 7rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 1.5rem;

}


.PastClientLogosImg1{
    width: 100%;
    margin: 1rem;

}
.PastClientLogosImg2{
    height: 100%;
    margin: 1rem;

}

.AllProjDispCont{
    /* z-index:-9; */
    margin: 1rem 2rem;
    margin-top: 5rem;
    
    /* padding-top: 4rem; */
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    
}
.SingProjDisp{
    position:relative;
    width:20rem;
    height: 20rem;
    border: 1px solid rgb(232, 232, 232);
    border-radius: 1rem;
    margin: .3rem;
    cursor: pointer;

    
}

.SingProjDisp:hover{
    border: 1px solid transparent;
    background-color:rgb(237, 237, 237); 
}
.SingProjDispClientName{
    font-size: .7rem;
    padding: 1rem;
    color: rgb(181, 179, 179);


}


.AllProjViewTitle{
    padding:  2rem;
    width: 70%;
    line-height: 1.4rem;
    color: grey;
}

.ProjViewTitle{
    font-size: 1.6rem;
    line-height: auto;
    padding: 1rem 0;
    width: 40%;    
    color: grey;
}



.AllProjViewLogoCont{
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    
    display: flex;
    justify-content: right;
    /* width: 100%; */
    width: 8rem;
    
    /* margin-left:auto;
    margin-right: auto; */
    
   
    /* height: 40%; */
}

.AllProjViewLogo{
    padding: 2rem;
    /* width: 8rem; */
    width: 100%;
    /* height: 100%; */
    /* width: 100%; */
    /* position: absolute; */
    /* top: 0; */
    /* right: 0; */
}


.ProjViewLogoCont{
    display: flex;
    justify-content: center;
    width: 100%;
    
    height: 40%;
}
.ProjViewLogo{
    padding: 1rem;
    width: 15rem;
}

.ExpProjViewCont{
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* width: 100%; */
    
    height: 80vh;
    margin: 1rem 2rem;
    margin-top:5rem;

    border: 1px solid rgb(217, 217, 217);
    border-radius: 1rem;

}

.ExpProjHeader{

    display: flex;
    position: relative;

}

.ProjBackBt{
     color: rgb(188, 188, 188);
    /* color:white; */
    font-size: 1.2rem;
    padding-left: 1rem;
    margin-left: 1rem;
    cursor: pointer;
    /* position:fixed; */
    border: 1px solid rgb(235, 232, 232);
     /* background-color: #0073ff; */
     background-color: white;
    border-radius: .6rem;
    display: flex;
    align-items: center;
    width: 5rem;
    height: 42px;
    /* position: absolute; */
}

.ProjBackBt:hover {
    background-color: black;
    color: rgb(218, 218, 218);

}

.SingProjViewBackBt{
    font-size: .8rem;
    padding: 0 .4rem;
}

.WorkClientName{

    font-size: .6rem;
    color: rgb(198, 198, 198);

}

.CapabSelect{
    display: flex;
}

/* Tablet version */









/* Mobile Version */

