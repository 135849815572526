@media screen and (min-width:1024px) {
    
    .Header{
        
        width: 100%;
        display: flex;
        background-color: black;
    }
    

.huLogo{
    width: 4rem;
    margin-left:16rem;
    border-right:1px solid rgb(90, 90, 90);
    border-left:1px solid rgb(90, 90, 90);
}

.NavTop{

    height:4rem;
    width: 100%;

}
.NavTopDiv{

    display: flex;
    text-decoration: none;
    background-color: black;
    height:4rem;
    text-decoration: none;
    list-style: none;
    margin: 0rem;
    padding-left: 1.5rem;
    
}

.NavItem{

    text-decoration: none;
    list-style: none;
    padding: 0 1.6rem 0 0;
    margin-top: 2rem;
    font-size: .8rem;
    /* line-height: 2rem; */
    color: rgb(145, 145, 145);

}

.NavTopDiv a{
    text-decoration: none;
    /* color: white; */
    color: rgb(189, 189, 189);

}

.NavItem Link {
    text-decoration: none;
    padding: 0;
    margin: 0;

}



}



/* ********************** */

/* @media screen and (max-width:45rem) { */
    @media screen and (max-width:1024px) {



.Header{
        
    width: 100%;
    display: flex;
   
}


.huLogo{
    width: 4rem;
    margin-left:0rem;
    border-right:1px solid grey;
}


.NavTop{
    height:4rem;
    width: 100%;
    

}
.NavTopDiv{

display: flex;
text-decoration: none;
background-color: black;
height:4rem;
text-decoration: none;
list-style: none;
margin: 0rem;
padding-left: 1.5rem;

}

.NavItem{

    text-decoration: none;
    list-style: none;
    padding: 0 1.2rem 0 0;
    margin-top: 2rem;
    font-size: .8rem;
    /* line-height: 2rem; */
    /* color: rgb(145, 145, 145); */

}

.NavTopDiv a{

    text-decoration: none;
    /* color: white; */
    color: rgb(189, 189, 189);

}

.NavItem Link {

    text-decoration: none;
    padding: 0;
    margin: 0;

}



}







/* ********************** */

/* @media screen and (max-width:45rem) { */
    @media screen and (max-width:720px) {

.NavTop{
    height:4rem;
    width: 100%;
    display: none;
    
}

    }